import {
  CookieConstants,
  Country,
  InvalidSessionPath,
  paramCookies,
  SupportedLocales,
} from '@/constants'
import { getCookie as getCookieUtil, setCookie } from '@/utils/cookie'
import { isServer } from '@/utils/isServer'
import { error } from '@/utils/log'

import { useRouter, useSearchParams } from 'next/navigation'
import { CookieData } from './cookie.types'

export const useClientCookie = (checkSession: boolean = true): CookieData => {
  let hasHeaderValue = false
  const router = useRouter()
  const searchParams = useSearchParams()
  if (isServer()) return {}

  const url = new URL(window.location.href)

  const getCookie = (name: CookieConstants): string => {
    const param = paramCookies[name]
    let value = searchParams.get(param)
    if (value) {
      hasHeaderValue = true
      setCookie(name, value)
      url.searchParams.delete(param)
    }

    if (value) return value

    value = getCookieUtil(name) ?? null

    return value ?? ''
  }

  const registerCookieValue = getCookie(CookieConstants.Register)

  const registerConsumer = registerCookieValue?.includes('consumer')
  const registerBusiness = registerCookieValue?.includes('business')

  const allowedMethods = (
    getCookie(CookieConstants.LoginMethods)?.split(',') ?? []
  ).filter(method => !!method)

  const sessionId = getCookie(CookieConstants.SessionId)

  const cookieValues = {
    sessionId,
    clientId: getCookie(CookieConstants.ClientId),
    loginUrl: getCookie(CookieConstants.LoginUrl),
    iFrame: getCookie(CookieConstants.IFrame),
    iFrameOrigin: getCookie(CookieConstants.Origin),
    lang: getCookie(CookieConstants.Language) as SupportedLocales,
    country: getCookie(CookieConstants.Country) as Country,
    pageTitle: getCookie(CookieConstants.PageTitle),
    allowedMethods,
    registerConsumer,
    registerBusiness,
  }

  if (!sessionId && checkSession && !hasHeaderValue) {
    error('No session found, redirecting to /login-failed')

    router.replace(InvalidSessionPath)
  }

  return cookieValues
}
