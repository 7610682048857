import { isMobileClient } from '@hi3g-access/utils'

const useIsMobile = (): boolean => {
  if (typeof window === 'undefined') {
    return false
  }

  const client = new URLSearchParams(document.location.search).get('client')

  return client ? isMobileClient(client) : false
}

export default useIsMobile
