import { isDevelopment } from '@/config'
import { SessionConstants } from '@/constants'
import logger from '@hi3g-access/client-logger'

import isProduction from '@/utils/isProduction'
import { ErrorCode } from './errorMapper'
import { isServer } from './isServer'

const log = (
  ...message: (
    | boolean
    | string
    | number
    | null
    | undefined
    | ErrorCode
    | object
  )[]
): void => {
  const sessionId = isServer()
    ? ''
    : sessionStorage.getItem(SessionConstants.SessionId)

  if (isServer() || !isProduction()) console.log(...message)
  if (!isDevelopment && !isServer())
    logger.info({
      component: 'CustomerLogin',
      message: message.join(' '),
      loginSessionId: sessionId,
      scope: 'customer-login',
    })
}

export const error = (
  ...message: (
    | boolean
    | string
    | number
    | null
    | undefined
    | ErrorCode
    | object
  )[]
): void => {
  const sessionId = isServer()
    ? ''
    : sessionStorage.getItem(SessionConstants.SessionId)

  if (isServer() || !isProduction()) console.error(...message)
  if (!isDevelopment && !isServer()) {
    logger.error({
      message: message.join(' '),
      component: 'CustomerLogin',
      loginSessionId: sessionId,
      scope: 'customer-login',
    })
  }
}

export default log
