import { defineMessages } from 'react-intl'

const messagesPath = 'customer-login.components.MitIdForm'

export default defineMessages({
  mitIdLoginAction: {
    defaultMessage: 'Log on with MitID',
    id: `${messagesPath}.mitIdLoginAction`,
  },

  genericErrorTitle: {
    defaultMessage: 'Error',
    id: `${messagesPath}.genericErrorTitle`,
  },
  genericErrorMessage: {
    defaultMessage: 'Something went wrong',
    id: `${messagesPath}.genericErrorMessage`,
  },
})
