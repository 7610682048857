'use client'
import useFormatMessage from '@/app/hooks/useFormatMessage'
import { FormatXMLElementFn, Options } from 'intl-messageformat'
import { MessageDescriptor, PrimitiveType } from 'react-intl'

type TranslationMessageProps = {
  message: MessageDescriptor
  values?: Record<
    string,
    React.ReactNode | PrimitiveType | FormatXMLElementFn<string, string>
  >
  opts?: Options
}

const T = ({ message, values, opts }: TranslationMessageProps) => {
  const formatMessage = useFormatMessage()
  return formatMessage(message, values, opts)
}

export default T
