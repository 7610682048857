import log from './log'

const sendMessage = (data: {
  msg: string
  result: 'success' | 'error' | 'cancelled'
  targetOrigin?: string
}) => {
  log('[Send Message] data:', data)
  const { msg, result } = data
  const message = `customerLogin::${JSON.stringify({ msg, result })}`
  window?.parent?.postMessage(message, data?.targetOrigin ?? '')
}

export default sendMessage

export const iFrameError = (msg: string) =>
  sendMessage({ msg, result: 'error' })
export const cancelIFrame = () =>
  sendMessage({ msg: 'Manually cancelled login', result: 'cancelled' })
