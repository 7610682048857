/*
 * General utils for managing cookies in Typescript.
 */
export function setCookie(name: string, val: string, timeout = 7) {
  const date = new Date()
  const value = val

  // Set it expire in 7 days
  date.setTime(date.getTime() + timeout * 24 * 60 * 60 * 1000)

  // Set it
  document.cookie =
    name +
    '=' +
    encodeURI(value) +
    '; expires=' +
    date.toUTCString() +
    '; path=/; sameSite=strict;secure=true;'
}

export function getCookie(name: string) {
  const value = '; ' + document.cookie
  const parts = value.split('; ' + name + '=')

  if (parts.length == 2) {
    const cookieValue = parts.pop()?.split(';').shift()
    return cookieValue ? cookieValue : undefined
  }
}

export function deleteCookie(name: string) {
  const date = new Date()

  // Set it expire in -1 days
  date.setTime(date.getTime() + -1 * 24 * 60 * 60 * 1000)

  // Set it
  document.cookie = name + '=; expires=' + date.toUTCString() + '; path=/'
}
