import { SessionConstants } from '@/constants'
import sendMessage from '@/utils/iFrameUtils'
import isIframe from '@/utils/isIFrame'
import { useRouter } from 'next/navigation'
import { useClientCookie } from './useCookie/useClientCookie'

const useFinalRedirect = () => {
  const router = useRouter()
  const { iFrameOrigin } = useClientCookie(false)
  return (url: string) => {
    const isMock = window.sessionStorage.getItem(SessionConstants.Mock)

    if (isMock === 'true' && !url.includes('mitid')) {
      window.sessionStorage.removeItem(SessionConstants.Mock)
      const [redirect, query] = url.split('?')
      router.push(`/mock/complete?${query}&redirectUrl=${redirect}`)
    }

    if (isIframe) {
      sendMessage({
        msg: url,
        result: 'success',
        targetOrigin: iFrameOrigin,
      })
    } else {
      router.push(url)
    }
  }
}

export default useFinalRedirect
