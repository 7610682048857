export const environment = {
  production: 'production',
  stage: 'stage',
  test: 'test',
  local: 'local',
} as const

export type Environment = (typeof environment)[keyof typeof environment]

export type Environments = Readonly<Record<Environment, string>>
