import { useGlobalContext } from '@/contexts/GlobalContext'
import {
  FormatXMLElementFn,
  Options as IntlMessageFormatOptions,
  PrimitiveType,
} from 'intl-messageformat'
import { MessageDescriptor, useIntl } from 'react-intl'

const formatKey = (key: string) => key.split('.').splice(1).join('.')

const useFormatMessage = () => {
  const { formatMessage } = useIntl()
  const context = useGlobalContext()
  return (
    descriptor: MessageDescriptor,
    values?: Record<
      string,
      React.ReactNode | PrimitiveType | FormatXMLElementFn<string, string>
    >,
    opts?: IntlMessageFormatOptions,
  ) =>
    context.intlDemoMode
      ? formatKey(descriptor.id as string)
      : formatMessage(
          descriptor,
          values as Record<
            string,
            PrimitiveType | FormatXMLElementFn<string, string>
          >,
          opts,
        )
}

export default useFormatMessage
