'use client'
import useIsMobile from '@/app/hooks/useIsMobile'
import * as React from 'react'
import { createContext, useContext, useState } from 'react'

type PageError = {
  title: string
  content: string | React.ReactNode
  errorCode?: string | number
  errorLocationId?: string
  page?: string
}

// dynamic values
export type GlobalContextState = {
  isMobileApp: boolean
  error?: PageError
  intlDemoMode: boolean
  bgImageNumber: number
}

// static values
export type GlobalContextProviderType = GlobalContextState & {
  isInitialized: boolean
  setError: (error?: PageError) => void
  toggleIntlMode: () => void
  setIsInitialized: (isInitialized: boolean) => void
}

const GlobalContext = createContext({} as GlobalContextProviderType)
const bgImageNumber = new Date().getMinutes()

export const GlobalContextProvider: React.FC<React.PropsWithChildren> = ({
  children,
  ...props
}) => {
  const isMobileApp = useIsMobile()
  const [isInitialized, setIsInitialized] = useState(false)
  const [intlDemoMode, setIntlDemoMode] = useState<boolean>(false)
  const [error, setError] = useState<PageError>()

  const toggleIntlMode = () => {
    setIntlDemoMode(demoMode => !demoMode)
  }

  const state: GlobalContextState = {
    ...props,
    isMobileApp,
    intlDemoMode,
    bgImageNumber,
    error,
  }

  const contextState: GlobalContextProviderType = React.useMemo(
    () => ({
      ...state,
      isInitialized,
      setError,
      toggleIntlMode,
      setIsInitialized,
    }),
    [state],
  )

  return (
    <GlobalContext.Provider value={contextState}>
      {children}
    </GlobalContext.Provider>
  )
}

export const useGlobalContext = (): GlobalContextProviderType =>
  useContext(GlobalContext)
