export enum LoginType {
  BANKID = 'bank_id',
  EMAIL = 'email',
  MITID = 'mit_id',
  SMS = 'sms_code',
  OTP = 'verify',
}

export enum CountryCallingCode {
  se = '46',
  dk = '45',
}

export type SupportedLocales = 'sv' | 'en' | 'da'
export type Country = 'se' | 'dk'
export const languages: { [countryCode: string]: SupportedLocales } = {
  se: 'sv',
  dk: 'da',
  en: 'en',
}

export enum SessionConstants {
  Country = 'cl-country',
  ClientId = 'cl-clientId',
  LoginUrl = 'cl-loginUrl',
  LoginMethods = 'cl-loginMethods',
  PageTitle = 'cl-pageTitle',
  SessionId = 'cl-sessionId',
  Jwt = 'cl-jwt',
  Mock = 'cl-mock',
  Register = 'cl-register',
  ErrorCode = 'cl-errorCode',
}

export enum CookieConstants {
  SsoCookie = 'LGNSSO',
  Language = 'cl-lang',
  OrgNumber = 'orgNumber',
  PhoneNumber = 'phoneNumber',
  Country = 'cl-country',
  ClientId = 'cl-clientId',
  LoginUrl = 'cl-loginUrl',
  LoginMethods = 'cl-loginMethods',
  PageTitle = 'cl-pageTitle',
  jwt = 'cl-jwt',
  mock = 'cl-mock',
  Register = 'cl-register',
  IFrame = 'cl-iframe',
  Origin = 'cl-origin',
  SessionId = 'cl-sessionId',
}

export const cookieParams: Record<string, CookieConstants> = {
  lang: CookieConstants.Language,
  iframe: CookieConstants.IFrame,
  origin: CookieConstants.Origin,
  sessionId: CookieConstants.SessionId,
  allowedLoginMethods: CookieConstants.LoginMethods,
  register: CookieConstants.Register,
  country: CookieConstants.Country,
  clientName: CookieConstants.PageTitle,
  clientId: CookieConstants.ClientId,
  loginUrl: CookieConstants.LoginUrl,
  customerName: CookieConstants.PageTitle,
}

// Reverse of above object
export const paramCookies: Record<CookieConstants, string> = Object.keys(
  cookieParams,
).reduce(
  (paramCookies, cookieParam) => ({
    ...paramCookies,
    [cookieParams[cookieParam]]: cookieParam,
  }),
  {},
) as Record<CookieConstants, string>

export const VerifyRouteHandler = '/auth/verify'
export const EmailRouteHandler = '/auth/email'
export const ResetNewRouteHandler = '/reset/new'
export const SelectRouteHandler = '/auth/select'

export const RegisterBusinessAdmin = '/api/register-business-admin'
export const InvalidSessionPath = '/invalid-session'
export const LoginFailedPath = '/login-failed'
export const RegisterAddPath = '/user/register/add'
export const CustomerSelectPath = '/customer/select'
export const RegisterUserPath = (customerType: string) =>
  `/user/register/${customerType}/new`
export const SecureCookieOptions = {
  secure: true,
  httpOnly: true,
  sameSite: 'strict' as const,
  path: '/',
}

export const EMAIL_MAX_LENGTH = 64
export const PASSWORD_MAX_LENGTH = 128
export const PHONENUMBER_MAX_LENGTH = 12
export const TAXPAYERID_MAX_LENGTH = 20
export const OTP_MAX_LENGTH = 6
export const FIRSTNAME_MAX_LENGTH = 32
export const LASTNAME_MAX_LENGTH = 64
